import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "keyIndustries" }
const _hoisted_2 = {
  key: 0,
  class: "common_title"
}
const _hoisted_3 = { class: "panel_con" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

import { onMounted, ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  const list = ref([]);
  const baseUrl = globalProperties.baseUrl
  const router = useRouter()

  onMounted(() => {
    // 通过api获取数据列表
    globalProperties.$API.keyIndustriesType().then((res: any) => {
      if(res.data.code == 200) {
        list.value = res.data.data
      }
    })
  })

  function eventClick(line: any) {
    if(line.type == 1) {
      window.open(line.externalLink)
    }else router.push(`/keyIndustriesList/${ line.parentId }/${ line.cateId }`)
  }


return (_ctx: any,_cache: any) => {
  const _component_icon_right = _resolveComponent("icon-right")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "key_item",
        key: (item as any).cateId
      }, [
        ((item as any).parentId == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString((item as any).cateName), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((item as any).children, (line1) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "item_panel pointer",
              onClick: ($event: any) => (eventClick(line1))
            }, [
              _createElementVNode("img", {
                src: _unref(baseUrl) + line1.coverAddress
              }, null, 8, _hoisted_5),
              _createElementVNode("div", null, [
                _createElementVNode("h3", null, _toDisplayString(line1.cateName), 1),
                _createVNode(_component_a_button, { type: "text" }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createTextVNode("查看")),
                    _createVNode(_component_icon_right)
                  ]),
                  _: 1
                })
              ])
            ], 8, _hoisted_4))
          }), 256))
        ])
      ]))
    }), 128))
  ]))
}
}

})